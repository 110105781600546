import AWS from 'aws-sdk';
import { formatString } from '../../../utils';

export const S3_BUCKET = process.env.REACT_APP_aws_bucketName;
export const REGION = process.env.REACT_APP_aws_region;

AWS.config.update({
	accessKeyId: process.env.REACT_APP_aws_accessKeyId,
	secretAccessKey: process.env.REACT_APP_aws_secretAccessKey,
	region: REGION,
});
export { AWS };

export const MAX_FILE_SIZE = 300;
export const DEFAULT_ALLOWED_FILES = ['pdf'];
export const allFileTypes: Record<string, string> = {
	csv: 'text/csv',
	xls: 'application/vnd.ms-excel',
	xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	pdf: 'application/pdf',
};
export const fileTypeErrorMessage =
	'You have uploaded a file of type {}. Please upload a {} file.';

export const maxFileSizeErrorMessage =
	'File size exceeds the maximum limit of {}MB.';

export const URL_EXPIRATION_TIME = 3600;

type FileListItem = {
	name: string;
	url: string;
	awsFileName: string;
	key: string;
};

export const defaultFile: FileListItem = {
	name: '',
	url: '',
	awsFileName: '',
	key: '',
};

type FileUploadItem = {
	file: File;
	name: string;
	url: string;
	key: string;
};

export const validateFiles = (
	files: File[],
	allowedFileTypes: string[],
	maxFileSize: number
): {
	isValid: boolean;
	error: {
		file: File;
		message: string;
	} | null;
} => {
	const result = {
		isValid: true,
		error: null as { file: File; message: string } | null,
	};

	for (let i = 0; i < files.length; i++) {
		const file = files[i];
		const fileType = file.type;
		const fileSize = file.size;

		if (
			!allowedFileTypes.some(
				(type) => fileType === allFileTypes[type as keyof typeof allFileTypes]
			)
		) {
			result.isValid = false;
			const message = formatString(
				fileTypeErrorMessage,
				fileType,
				allowedFileTypes.join('/ ').toUpperCase()
			);
			result.error = {
				file,
				message,
			};
			return result;
		}

		if (fileSize > maxFileSize * 1024 * 1024) {
			result.isValid = false;
			const message = formatString(maxFileSizeErrorMessage, maxFileSize);
			result.error = {
				file,
				message,
			};
			return result;
		}
	}

	return result;
};

export const getExtensionFromFileName = (fileName: String) => {
	const dotIndex = fileName.lastIndexOf('.');
	if (dotIndex === -1) {
		return ''; // Return empty string if no extension found
	}
	return fileName.slice(dotIndex + 1).toLowerCase();
};

export const getUniqueFileName = (
	fileList: FileListItem[],
	fileName: string
): string => {
	let uniqueFileName = fileName;
	let index = 1;

	if (!fileList || !fileList.length) {
		return fileName;
	}

	while (fileList.some((file) => file.name === uniqueFileName)) {
		const extensionIndex = fileName.lastIndexOf('.');
		if (extensionIndex !== -1) {
			uniqueFileName = `${fileName.slice(
				0,
				extensionIndex
			)}${index}${fileName.slice(extensionIndex)}`;
		} else {
			uniqueFileName = `${fileName}${index}`;
		}
		index++;
	}

	return uniqueFileName;
};

export const generatePreSignedUrl = async (
	fileName: string,
	myBucket: AWS.S3
): Promise<string> => {
	if (
		!myBucket ||
		!myBucket.config ||
		!myBucket.config.params ||
		!myBucket.config.params.Bucket
	) {
		throw new Error('Invalid myBucket configuration');
	}

	const bucketName = myBucket.config.params.Bucket;
	const params = {
		Bucket: bucketName,
		Key: fileName,
		Expires: URL_EXPIRATION_TIME, // URL expiration time in seconds
	};
	try {
		const url = await myBucket.getSignedUrlPromise('getObject', params);
		return url;
	} catch (error) {
		throw new Error('Error generating pre-signed URL');
	}
};

export const awsFileUploader = async (
	files: FileUploadItem[],
	progressSetter: React.Dispatch<React.SetStateAction<number>>,
	bucketName: string
): Promise<any[]> => {
	const totalFiles = files.length;
	let uploadedFiles = 0;
	let uploadResults: any[] = [];

	const handleUploadComplete = async () => {
		uploadedFiles++;
		const progress = Math.round((uploadedFiles / totalFiles) * 100);
		progressSetter(progress);
	};

	const uploadPromises = files.map((fileUploadItem) => {
		const formData = new FormData();
		formData.append('file', fileUploadItem.file);
		formData.append('fileName', fileUploadItem.name);
		formData.append('key', fileUploadItem.key);
		formData.append('bucketName', bucketName);

		return new Promise(async (resolve, reject) => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_backend_api_url}/upload-file`,
					{
						method: 'POST',
						body: formData,
					}
				);

				if (response.status !== 201) {
					throw new Error('Failed to upload file');
				}

				await handleUploadComplete();
				const data = await response.json();
				const uploadData: FileListItem = {
					url: data.url,
					name: fileUploadItem.name,
					awsFileName: fileUploadItem.key,
					key: data.Key,
				};
				resolve(uploadData);
			} catch (error) {
				reject(error);
			}
		});
	});

	try {
		progressSetter(2);
		uploadResults = await Promise.all(uploadPromises);
	} catch (error) {
		progressSetter(0);
		throw error;
	}

	return uploadResults;
};

export const AWSFileDelete = async (
	Keys: string | string[],
	progressSetter: React.Dispatch<React.SetStateAction<number>>,
	bucketName: string
): Promise<boolean> => {
	const deleteKeys = Array.isArray(Keys) ? Keys : [Keys];
	const totalKeys = deleteKeys.length;
	let deletedKeys = 0;

	const handleDeleteComplete = () => {
		deletedKeys++;
		const progress = Math.round((deletedKeys / totalKeys) * 100);
		progressSetter(progress);
	};

	const deletePromises = deleteKeys.map(async (key) => {
		const deleteParams = {
			bucketName,
			key,
		};

		return fetch(`${process.env.REACT_APP_backend_api_url}/delete-file`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
			},
			body: JSON.stringify(deleteParams),
		})
			.then((response) => {
				if (response.status !== 204) {
					throw new Error(
						`Failed to delete file. Status code: ${response.status}`
					);
				}
				handleDeleteComplete();
			})
			.catch((error) => {
				throw new Error(`Error deleting files: ${error}`);
			});
	});

	try {
		await Promise.all(deletePromises);
		// progressSetter(0);
		return true;
	} catch (error) {
		// Handle error
		progressSetter(0);
		return false;
	}
};

export const openFile = (url: string) => {
	window.open(url, '_blank', 'noopener,noreferrer');
};
