import { supabase } from '../config/Supabase';

import axiosInstance from './axiosInstance';
import { ApiResponse } from './types';

export const signIn = async (email: string, password: string) => {
	try {
		const { user, error, session } = await supabase.auth.signIn({
			email: email,
			password: password,
		});

		if (error) {
			throw error;
		}

		const accessToken = session?.access_token;

		return { user, accessToken };
	} catch (error) {
		throw error;
	}
};

export const signUp = async (
	email: string,
	password: string,
	redirectTo: string,
	metaData: {} = {}
) => {
	try {
		let options: any = {
			data: metaData,
		};
		const { user, error, session } = await supabase.auth.signUp(
			{
				email: email,
				password: password,
			},
			options
		);
		if (error) {
			throw error;
		}

		const accessToken = session?.access_token;

		return { user, accessToken };
	} catch (error) {
		throw error;
	}
};
export const confirmSignup = async (): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.put(`/v1/me/user-confirm-signup`, {});

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const signOut = async () => {
	try {
		const { error } = await supabase.auth.signOut();

		if (error) {
			throw error;
		}

		return true;
	} catch (error) {
		throw error;
	}
};

export const resetPassword = async (email: string) => {
	try {
		const { data, error } = await supabase.auth.api.resetPasswordForEmail(
			email
		);

		if (error) {
			throw error;
		}

		return data;
	} catch (error) {
		throw error;
	}
};

export const changePassword = async (
	accessToken: string,
	newPassword: string
) => {
	try {
		const { error, data } = await supabase.auth.api.updateUser(accessToken, {
			password: newPassword,
		});

		if (error) {
			throw error;
		}

		if (!data) {
			throw new Error("Error updating user's password");
		}

		return data;
	} catch (error) {
		throw error;
	}
};

export const refreshUserToken = async () => {
	try {
		const { error, data } = await supabase.auth.refreshSession();

		if (error) {
			throw error;
		}
		return data;
	} catch (error) {
		throw error;
	}
};
