import { defaultFile } from '../components/utils/fileUpload/fileHelpers';
import {
	Default_BAS_Details,
	buildingDocumentsNameMap,
} from '../components/dashboard/dataSharing/const';
export const DefaultDataSharingTrackingItem = {
	BAS_SETUP: 0,
	BUILDING_DOCUMENTS: 0,
	ENERGY_DIRECT: 0,
	ENERGY_STAR: 0,
	BUILDING_DETAILS: 0,
};
// TODO: add types to all the intitial input sheet values

export const initialInputSheet = {
	// These values will be filled when the building is intially created
	companyId: '',
	companyName: '',
	buildingName: '',
	city: '',
	isCopy: false,
	buildingCopy: '',
	hasOpenedBuildingInfo: false,
	inputComponentStarted: [
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
	],
	// These values all come from data sharing
	dataShareProgressTracking: DefaultDataSharingTrackingItem,
	energyDirectAccountNum: [],
	energyDirectWebAccessCode: [],
	isEnergyStarShared: false,
	isEnergyDirectPremium: '',
	requestedAccess: false,
	requestedAccessMessage: '',
	fileToDownload: { name: '', url: '' },
	fileUpload: [defaultFile],
	moveForward: false,
	makePremium: '',
	isWithoutPremium: '',
	hasGivenDirectAccess: false,
	address: '',
	state: '',
	zip: '',
	basSystem: '',
	waterBillFile: [],
	control_drawing: [],
	isGarageIncludedInUtilityBill: '',
	isSubmeterInParkingGarage: '',
	garageFile: { name: '', url: '' },
	mepDrawingsFile: [],
	elevationsFile: [],
	floorPlansFile: [],
	structuralShopFile: [],
	sectionDetailFacadeFile: [],
	glazingShopFile: [],
	// Building Info fields
	typeOfTerrain: '',
	numFloors: '', // number
	totalFloorArea: '',
	commonAreaPercent: '', // number
	buildingPurpose: [],
	//Air Leakage field
	airLeakageLevels: {},
	// Lighting fields
	percentageLightInstalled: {},
	occupancySensor: ['', ''],
	lightingScheduleOnWeekdays: [
		['', ''],
		['', ''],
	],
	lightingScheduleOnSaturdays: [
		['', ''],
		['', ''],
	],
	lightingScheduleOnSundays: [
		['', ''],
		['', ''],
	],
	lightingControlsAmount: [null, null],
	lightingControlsUsage: [null, null],
	lightingAfterHoursUsage: [null, null],
	brightnessLightInstalled: {},
	numFixturesPerFloor: '', // number
	// Building Energy Management System fields
	buildingEnergyManagementSys: '',
	buildingEnergyManagementPractices: [],
	// Solar Panel System fields
	photovoltaicSystem: '',
	pvPanelPeakPowerCoefficient: '',
	typeOfPhotovoltaic: '',
	pvPerformanceFactor: '',
	ventilationOfPhotovoltaic: '',
	orientationOfPhotovoltaic: '',
	angleOfPhotovoltaic: '',
	areaOfEachPanel: '', // number
	numberOfArrays: '',
	voltageSupplied: '',
	// Solar Water Heating System fields
	solarWaterHeatingSys: '',
	// orientationOfSolarWaterHeatingSys: '',
	areaOfEachHeatingPanel: '', // number
	numberOfHeatingArrays: '', // number
	angleOfSolarWaterHeatingSys: '',
	dhwPerDay: '',
	// Data Sharing fields
	energyDirect: '',
	energyStar: '',
	basDataLink: '',
	// Domestic Hot Water fields
	boilerType: '',
	dhwEfficiency: {
		value: '',
		type: 'COP',
	},
	dhwGallonsPerDay: '',
	distanceOfHotWaterComingFrom: '',
	typeOfGenerationSys: '',
	capacityOfGenerationSys: '',
	// Internal Heat Gain fields
	peopleInBuildingOnWeekdays: '',
	occupiedHoursWeekdays: ['', ''],
	setIfOccupiedOnSaturdays: null,
	peopleInBuildingOnSaturdays: '',
	occupiedHoursSaturdays: ['', ''],
	setIfOccupiedOnSundays: null,
	peopleInBuildingOnSundays: '',
	occupiedHoursSundays: ['', ''],
	equipmentPowerDensity: null,
	// Temperature Setpoint fields
	setPointTemperatureForHeatingOnWeekdays: ['', ''],
	occHeatSetPointWeekdays: '',
	unoccHeatSetPointWeekdays: '',
	setPointTemperatureForHeatingOnSaturdays: ['', ''],
	occHeatSetPointSaturdays: '',
	unoccHeatSetPointSaturdays: '',
	setPointTemperatureForHeatingOnSundays: ['', ''],
	occHeatSetPointSundays: '',
	unoccHeatSetPointSundays: '',
	setPointTemperatureForCoolingOnWeekdays: ['', ''],
	occCoolSetPointWeekdays: '',
	unoccCoolSetPointWeekdays: '',
	setPointTemperatureForCoolingOnSaturdays: ['', ''],
	occCoolSetPointSaturdays: '',
	unoccCoolSetPointSaturdays: '',
	setPointTemperatureForCoolingOnSundays: ['', ''],
	occCoolSetPointSundays: '',
	unoccCoolSetPointSundays: '',
	minimumHeatingSetPoint: '',
	maximumCoolingSetPoint: '',
	// Garage Schedule fields
	numberOfChargingStations: '',
	chargingPowerOfEVStation: '',
	chargingMake: '',
	chargingModel: '',
	garageFanHorsepower: '',
	// Roof Material fields
	areaOfRoof: '',
	materialOfRoof: '',
	R_value_roof: '',
	flatRoof: '',
	roof_layersData: [],
	// Wall Material fields
	materialOfWall: '',
	R_value_wall: '',
	areaOfWall: '',
	wall_layersData: [],
	// Window Material fields
	R_value_window: '',
	solarTransmittance: '',
	emissivity: '',
	overhang: '',
	fins: '',
	windowDecoration: [],
	// HVAC system fields
	hvacSystems: { 0: { motorSystems: { 0: {} } } },
	// New HVAC
	// Heating
	boilerPlant: { implemented: null, data: [] },
	electricResistance: { implemented: null, data: [] },
	districtHeatingConnection: { implemented: null, data: [] },
	// Cooling
	waterCooledChiller: { implemented: null, data: [] },
	airCooledChiller: { implemented: null, data: [] },
	dx: { implemented: null, data: [] },
	districtCoolingConnection: { implemented: null, data: [] },
	// Heat pumps
	airSourceHeatPump: { implemented: null, data: [] },
	waterSourceHeatPump: { implemented: null, data: [] },
	// Condensing
	coolingTower: { implemented: null, data: [] },
	// Distribution
	ahu: { implemented: null, data: [] },
	unitarySystems: { implemented: null, data: [] },
	hydronic: { implemented: null, data: [] },
	zoneTerminalUnits: { implemented: null, data: [] },
	swud: { implemented: null, data: [] },
	// Ventilation
	doasMau: { implemented: null, data: [] },
	erv: { implemented: null, data: [] },
	exhaust: { implemented: null, data: [] },
	// Pumps
	pumps: { implemented: null, data: [] },
	// Thermal zones
	thermalZones: { 0: {} },
	// Process loads
	processLoads: { 0: {} },
	// Helpful fields
	isDraft: true,
	isDataSharingComplete: false,
	basDetails: Default_BAS_Details,
};
