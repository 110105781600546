import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setAccessToken } from '../redux/auth/AuthSlice';
import { supabase } from '../config/Supabase';

export function WithAuth({ children }) {
	const dispatch = useDispatch();

	const accessToken = useSelector((state) => state.auth.accessToken);

	const checkTokenExpiration = React.useCallback(() => {
		const data = supabase.auth.session();
		if (!data) {
			dispatch(setAccessToken(''));
			return;
		}
		let { currentSession, expiresAt } = data;

		if (currentSession?.user) {
			// Check if the token has expired
			const timeNow = Math.round(Date.now() / 1000);

			if (timeNow > expiresAt) {
				// Token has expired, log the user out and redirect to login
				supabase.auth.signOut();
			}
		}
	}, [dispatch]);

	useEffect(() => {
		// Call the function once on first render
		checkTokenExpiration();
		// Check token expiration every minute (you can adjust the interval as needed)
		const tokenCheckInterval = setInterval(checkTokenExpiration, 60000);
		return () => {
			clearInterval(tokenCheckInterval);
		};
	}, [checkTokenExpiration]);

	let url = window.location.href.split('/#')[1];
	let urlParams = new URLSearchParams(url);
	let type = urlParams.get('type') || '';
	if (type === 'signup') {
		return <Navigate to="/signupConfirm" />;
	}
	if (type === 'recovery') {
		let access_token = urlParams.get('access_token') || '';
		let resetPasswordUrl = `/resetPassword?access_token=${access_token}`;
		return <Navigate to={resetPasswordUrl} />;
	} else {
		return accessToken ? children : <Navigate to="/login" />;
	}
}
