import axiosInstance from './axiosInstance';
import { ApiResponse } from './types';

type GroupFilter = {
	group_name?: string;
};

type GroupData = {
	target_percentage: string;
	selected_target: string;
	benchmark_year: string;
	target_year: string;
	role: number;
};

export const getGroups = async (
	filter: GroupFilter
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.get('/v1/groups', {
			params: filter || {},
		});

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const createGroup = async (data: {}): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.post('/v1/groups', data);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const checkGroupAvailability = async (
	filter: GroupFilter
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.get('/v1/groups/availability', {
			params: filter,
		});

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const updateGroup = async (
	groupId: string,
	groupData: GroupData
): Promise<ApiResponse<any>> => {
	const {
		target_percentage,
		selected_target,
		target_year,
		benchmark_year,
		role,
	} = groupData;

	try {
		const response = await axiosInstance.put(`/v1/groups/${groupId}`, {
			data: { target_percentage, selected_target, target_year, benchmark_year },
			role,
		});

		return response.data;
	} catch (error) {
		throw error;
	}
};
