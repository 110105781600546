export const states = [
	'Alabama (AL)',
	'Alaska (AK)',
	'Arizona (AZ)',
	'Arkansas (AR)',
	'California (CA)',
	'Colorado (CO)',
	'Connecticut (CT)',
	'Delaware (DE)',
	'Florida (FL)',
	'Georgia (GA)',
	'Hawaii (HI)',
	'Idaho (ID)',
	'Illinois (IL)',
	'Indiana (IN)',
	'Iowa (IA)',
	'Kansas (KS)',
	'Kentucky (KY)',
	'Louisiana (LA)',
	'Maine (ME)',
	'Maryland (MD)',
	'Massachusetts (MA)',
	'Michigan (MI)',
	'Minnesota (MN)',
	'Mississippi (MS)',
	'Missouri (MO)',
	'Montana (MT)',
	'Nebraska (NE)',
	'Nevada (NV)',
	'New Hampshire (NH)',
	'New Jersey (NJ)',
	'New Mexico (NM)',
	'New York (NY)',
	'North Carolina (NC)',
	'North Dakota (ND)',
	'Ohio (OH)',
	'Oklahoma (OK)',
	'Oregon (OR)',
	'Pennsylvania (PA)',
	'Rhode Island (RI)',
	'South Carolina (SC)',
	'South Dakota (SD)',
	'Tennessee (TN)',
	'Texas (TX)',
	'Utah (UT)',
	'Vermont (VT)',
	'Virginia (VA)',
	'Washington (WA)',
	'West Virginia (WV)',
	'Wisconsin (WI)',
	'Wyoming (WY)',
];

export const PAGE_KEYS = {
	BAS_SETUP_BUILDING_INFO: 0,
	BAS_SETUP_DEPLOYMENT: 1,
	BAS_SETUP_NETWORK: 2,
	BAS_SETUP_BACNET: 3,
	BAS_SETUP_EQUIPMENT: 4,

	BUILDING_DOCUMENTS: 5,

	ENERGY_DIRECT_ACCESS: 6,

	ENERGY_STAR_CONNECTION_STEPS: 7,
	ENERGY_STAR_SHARE_PROPERTY_STEPS: 8,

	BUILDING_DETAILS: 9,
};

export const Default_BAS_Details = {
	buildiingInfo: {
		address: '',
		state: '',
		zip: '',
	},
	development: {
		type: null,
		osAndVersion: null,
		dataSource: null,
	},
	network: {
		assignment: null,
		ips: {
			static: null,
			subnet: null,
			gateway: null,
		},
		outboundAccess: null,
		topologyDocument: [],
	},
	bacnet: {
		support: null,
		sameSubnet: null,
		devicesDiscoverable: null,
		subnetIPAddress: null,
	},
	equipment: {
		preferredTypes: null,
	},
};

export const buildingDocumentsNameMap = {
	MEP: 'MEP_drawings',
	ELEVATIONS: 'elevations',
	WATER_BILL: 'water_bill',
	CONTROL_DRAWING: 'control_drawing',
	FLOOR_PLANS: 'floor_plans_below_grade',
	STRUCTURAL_SHOP: 'structural_shop',
	SECTION_DETAIL_FACADE: 'section_detail_facade',
	GLAZING_SHOP: 'glazing_shop',
};

export const requiredBuildingDocuments = [
	buildingDocumentsNameMap.MEP,
	buildingDocumentsNameMap.ELEVATIONS,
	buildingDocumentsNameMap.WATER_BILL,
	buildingDocumentsNameMap.CONTROL_DRAWING,
];
