import { createTheme } from '@mui/material';
import globalVariables from './styles/_global.scss';

const colors = {
	primary: '#004225',
	secondary: '#00422599',
	white: '#fff',
	black: '#000',
};

const theme = createTheme({
	palette: {
		primary: {
			main: colors.primary,
		},
		secondary: {
			main: colors.secondary,
		},
	},
	components: {
		typography: {
			fontSize: '0.85rem',
			fontFamily: '"Inter", sans-serif',
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontSize: '0.85rem',
					fontFamily: '"Inter", sans-serif',
					textTransform: 'uppercase', // Set the desired text transform value
					'&:disabled': {
						color: '#909090', // Set the desired disabled color
					},
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					fontSize: '0.85rem',
					fontFamily: '"Inter", sans-serif',
					color: '#212121',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					border: `1px solid #e7e7e7`,
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					fontSize: '0.85rem',
					fontFamily: '"Inter", sans-serif',
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontSize: '0.85rem',
					fontFamily: '"Inter", sans-serif',
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					span: {
						fontSize: globalVariables.inputLabels,
					},
				},
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					fontSize: globalVariables.inputLabels,
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					margin: '15px 0 0 0',
					borderBottomWidth: 0,
				},
			},
		},
		MuiCircularProgress: {
			colorPrimary: {
				color: globalVariables.primary,
			},
		},
		MuiDataGrid: {
			styleOverrides: {
				root: {
					border: `2px solid ${globalVariables.neutral2}`, // Change row border color
				},
				withBorderColor: {
					borderColor: globalVariables.neutral3, // Change row border color
				},
				columnHeaders: {
					backgroundColor: globalVariables.neutral2, // Change header background color
					borderBottom: `2px solid`, // Change header border color
				},
				// cell: {
				// 	borderBottom: `2px solid blue`, // Change column border color
				// },
				columnHeaderTitle: {
					fontWeight: 'bold', // Change header font weight
					color: globalVariables.neutral7, // Change header font color
				},
			},
		},
	},
	overrides: {
		MuiTabs: {
			indicator: {
				backgroundColor: colors.black,
			},
		},
		MuiTab: {
			selected: {
				backgroundColor: colors.black,
			},
		},
	},
});

export default theme;
