import axiosInstance from './axiosInstance';
import { AxiosRequestConfig } from 'axios';
import { ApiResponse, CustomError } from './types';
import { propertyApi } from './';
import { userRoleMap } from '../utils/maps';

type UserFilter = {
	email: string;
	invited_by?: string;
	group_id?: string;
	invitation_id?: string;
};

export type PropUserFilter = {
	prop_id?: string;
	user_id?: string;
	group_id?: string;
	role?: number;
};

export type UserCreationPayload = {
	email: string;
	role: number;
	group_id: string;
	invited_by: string;
};

export const getUserDetail = async (
	userId: string
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.get(`/v1/users/me`);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getUsers = async (
	filter: UserFilter,
	accessToken?: string
): Promise<ApiResponse<any>> => {
	try {
		const headers: AxiosRequestConfig['headers'] = {
			'Content-Type': 'application/json',
		};

		if (accessToken) {
			headers.token = `${accessToken}`;
		}
		const response = await axiosInstance.get('/v1/users', {
			params: filter,
			headers,
		});

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const checkUserEmailAvailability = async (
	filter: UserFilter
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.get('/v1/users/availability', {
			params: filter,
		});

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const createUser = async (
	data: UserCreationPayload
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.post('/v1/users', data);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const updateUser = async (
	userId: string,
	data: {}
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.put(`/v1/users/${userId}`, data);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const updateCurrentUser = async ({
	data = {},
	params = {},
}: {
	data: {};
	params?: {};
}): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.put(`/v1/users/me`, data, {
			params,
		});

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getPropUsers = async (
	filter: PropUserFilter
): Promise<ApiResponse<any[]>> => {
	try {
		const response = await axiosInstance.get(`/v1/prop_users`, {
			params: filter,
		});

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const fetchUsersWithProperties = async (userFilter: UserFilter) => {
	try {
		const { data: userData } = await getUsers(userFilter);

		if (userData.length === 0) {
			return { data: [], error: null };
		}

		const usersWithProperties = await Promise.all(
			userData
				.filter((user: any) => user.role !== userRoleMap.Admin)
				.map(async (user: any) => {
					let propertyFilter: propertyApi.PropertyFilter = {
						role: user.role,
						user_id: user.user_id,
					};

					if (user.role === userRoleMap.Owner) {
						propertyFilter = {
							role: user.role,
							group_id: user.group_id,
						};
					}

					let properties = [];
					try {
						const propertyListData = await propertyApi.getPropertyList(
							propertyFilter
						);
						properties = propertyListData.data;
					} catch (error) {}

					return { ...user, property: properties };
				})
		);

		// Ensure at least one user object with an empty array for properties
		if (usersWithProperties.length === 0) {
			const emptyUser = { ...userData[0], property: [] };
			usersWithProperties.push(emptyUser);
		}

		return { data: usersWithProperties, error: null };
	} catch (error) {
		throw error;
	}
};

export const fetchUserDataWithPropertiesAndUsers = async (
	userFilter: UserFilter,
	currentUser: any
) => {
	try {
		const usersWithPropertiesResponse = await fetchUsersWithProperties(
			userFilter
		);
		const { data: usersWithProperties } = usersWithPropertiesResponse;

		if (usersWithProperties.length === 0) {
			return { data: [], error: null };
		}

		const finalData = await Promise.all(
			usersWithProperties.map(async (user: any) => {
				const propUserFilter: PropUserFilter = {
					user_id: user.user_id,
				};
				let propUsers = [];
				try {
					const propUsersData = await getPropUsers(propUserFilter);
					propUsers = propUsersData.data;
				} catch (error) {}

				return { ...user, prop_users: propUsers };
			})
		);

		return { data: finalData, error: null };
	} catch (error) {
		throw error;
	}
};

export const addPropertiesToUser = async (
	userId: string,
	data: any
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.post(
			`/v1/users/${userId}/properties`,
			data
		);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const updatePropertiesOfUser = async (
	userId: string,
	data: {}
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.put(
			`/v1/users/${userId}/properties`,
			data
		);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const deletePropertiesOfUser = async (
	userId: string
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.delete(
			`/v1/users/${userId}/properties`
		);

		if (response.status !== 204) {
			throw new CustomError(
				"User's properties could not be deleted",
				'DELETE_ERROR',
				response.status
			);
		}

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const deleteCurrentUser = async (): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.delete(`/v1/users/me`);

		if (response.status !== 204) {
			throw new CustomError(
				'Current User could bd not Deleted',
				'DELETE_ERROR',
				response.status
			);
		}

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const deletetUser = async (
	userId: string
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.delete(`/v1/users/${userId}`);

		if (response.status !== 204) {
			throw new CustomError(
				'User could bd not Deleted',
				'DELETE_ERROR',
				response.status
			);
		}

		return response.data;
	} catch (error) {
		throw error;
	}
};
