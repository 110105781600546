import axiosInstance from './axiosInstance';
import { ApiResponse } from './types';

export const getAnomalyData = async (
	filter: {} = {}
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.get(
			'/envelope-explorer/envelope-explorer-anomaly-data',
			{
				params: filter,
			}
		);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getModelLoader = async (
	filter: {} = {},
	modelType: string
): Promise<ApiResponse<any>> => {
	try {
		let url = 'envelope-explorer-model-loader';
		if (modelType === 'thermal') {
			url = 'envelope-explorer-thermal-model-loader';
		}
		const response = await axiosInstance.get(`/envelope-explorer/${url}`, {
			params: filter,
		});

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getImagesData = async (
	data: {} = {}
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.post(
			'/envelope-explorer/envelope-explorer-images',
			data
		);

		return response.data;
	} catch (error) {
		throw error;
	}
};
