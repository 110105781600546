import axiosInstance from './axiosInstance';
import { userApi } from '.';
import { ApiResponse, CustomError } from './types';

import { userRoleMap } from '../utils/maps';

export type PropertyFilter = {
	role: number;
	user_id?: string;
	group_id?: string;
	prop_name?: string;
	// Add more filter properties as needed
};

export const createProperty = async (
	name: string,
	region: string,
	city: string,
	group: string,
	inputSheet: {},
	role: number,
	email: string,
	user_id: string,
	group_id: string
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.post('/v1/properties', {
			name,
			region,
			city,
			group,
			inputSheet,
			role,
			email,
			user_id,
			group_id,
		});

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getPropertyList = async (
	filter: PropertyFilter
): Promise<ApiResponse<any[]>> => {
	try {
		// Make the API call to get the list of properties
		const response = await axiosInstance.get('/v1/properties', {
			params: filter,
		});

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getProperty = async (id: string): Promise<ApiResponse<any[]>> => {
	try {
		// Make the API call to get the list of properties
		const response = await axiosInstance.get(`/v1/properties/${id}`);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getPropertyWithPropUsers = async (
	proptyFilter: PropertyFilter
) => {
	try {
		const { data: properties } = await getPropertyList(proptyFilter);

		const propertiesWithUsers = await Promise.all(
			properties.map(async (property) => {
				let propUserFilter: userApi.PropUserFilter;

				if (proptyFilter.role === userRoleMap.Owner) {
					propUserFilter = {
						prop_id: property.prop_id,
					};
				} else {
					propUserFilter = {
						user_id: proptyFilter.user_id,
					};
				}

				let propUsers = [];
				try {
					// Retrieve the list of prop_users from the response
					const propUsersResponse = await userApi.getPropUsers(propUserFilter);
					propUsers = propUsersResponse.data;
				} catch (error) {}

				// Add the prop_users list to the property object
				return { ...property, prop_users: propUsers };
			})
		);

		return propertiesWithUsers;
	} catch (error) {
		throw error;
	}
};

export const updateProperty = async (
	id: string,
	dataToUpdate: any
): Promise<ApiResponse<any>> => {
	try {
		// Make the API call to update the property
		const response = await axiosInstance.put(
			`/v1/properties/${id}`,
			dataToUpdate
		);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const addUserToProperty = async (
	propertyId: string,
	data: any
): Promise<ApiResponse<any>> => {
	try {
		const response = await axiosInstance.post(
			`/v1/properties/${propertyId}/users`,
			data
		);

		return response.data;
	} catch (error) {
		throw error;
	}
};

export const archiveProperty = async (
	propertyId: string,
	data: {}
): Promise<ApiResponse<any>> => {
	try {
		// Make the API call to update the property
		const response = await axiosInstance.delete(
			`/v1/archive-building/${propertyId}`,
			{ data }
		);

		if (response.status !== 204) {
			throw new CustomError(
				'Property could bd not Deleted/archived successfully',
				'DELETE_ERROR',
				response.status
			);
		}

		return response.data;
	} catch (error) {
		throw error;
	}
};
