import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { propertyApi } from '../../api';
import { userRoleMap } from '../../utils/maps';

const initialState = {
	loading: true,
	list: [],
	error: '',
};

export const getBuildings = createAsyncThunk(
	'buildings/getBuildings',
	async (payload, thunkAPI) => {
		try {
			// Get propeties of just those users
			let proptyFilter = {
				role: payload.role,
				user_id: payload.user_id,
			};

			// Get all properties Owners in the group
			if (payload.role === userRoleMap.Owner) {
				proptyFilter = {
					role: payload.role,
					group_id: payload.group_id,
				};
			}
			const { data: properties } = await propertyApi.getPropertyList(
				proptyFilter
			);
			// const data = await propertyApi.getPropertyWithPropUsers(proptyFilter);

			// if (payload.role === 0) {
			// var { data, error } = await supabase
			// 	.from('property')
			// 	.select(`*, prop_users(*)`)
			// 	.eq('group_name', payload.group_name);
			// } else {
			// var { data, error } = await supabase
			// 	.from('property')
			// 	.select(`*, prop_users!inner(*)`)
			// 	.eq('prop_users.email', payload.email);
			// }

			if (properties && properties.length) {
				return properties;
			} else {
				return thunkAPI.rejectWithValue('user is not invited');
			}
		} catch (error) {
			return [];
		}
	}
);

const sortBuildingsByName = (data) => {
	return data.sort((a, b) => {
		const propA = a.prop_name.toUpperCase(); // Convert to uppercase for case-insensitive sorting
		const propB = b.prop_name.toUpperCase();

		if (propA < propB) {
			return -1; // propA comes before propB
		}
		if (propA > propB) {
			return 1; // propA comes after propB
		}
		return 0; // propA and propB are equal
	});
};

const buildingsSlice = createSlice({
	name: 'buildings',
	initialState,
	reducers: {
		updateBuildings: (state, { payload }) => {
			state.list = payload;
		},
		resetBuildings: (state) => {
			return {
				loading: true,
				list: [],
				error: '',
			};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getBuildings.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getBuildings.fulfilled, (state, action) => {
			state.loading = false;
			state.list = sortBuildingsByName(action.payload);
		});
		builder.addCase(getBuildings.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload;
		});
	},
});

export const { updateBuildings, resetBuildings } = buildingsSlice.actions;

export default buildingsSlice.reducer;
